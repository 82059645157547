<template>
  <section class="section shop">
    <div class="section__header text-center">
      <div class="container">
        <div class="row">
          <div
            class="col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"
          >
            <h1 class="section__title h3" v-t="'account.login.title'"></h1>
          </div>
        </div>
      </div>
    </div>

    <div class="section__body">
      <div class="container">
        <div class="row">
          <div
            class="col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"
          >
            <div
              class="box__content bg-light p-3 mb-3"
              v-if="this.$route.params.resetpassword"
              v-t="'account.reset-password.success'"
            ></div>
            <div
              class="box__content bg-light p-3 mb-3"
              v-if="form.errors.length"
            >
              <strong v-t="'account.login.error'">Something went wrong:</strong>
              <ul class="mt-2">
                <li v-for="(error, i) in form.errors" :key="i">{{ error }}</li>
              </ul>
            </div>
            <form @submit.prevent="submit" @submit="userLogin($event)">
              <div class="form-group mb-4">
                <div
                  class="d-flex align-items-center justify-content-between mb-3"
                >
                  <label
                    for="input-email"
                    class="label--big"
                    v-t="'account.login.email-label'"
                    >Email address</label
                  >
                </div>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': v$.form.email.$error }"
                  id="input-email"
                  name="email"
                  aria-describedby="email-1"
                  :placeholder="$t('account.login.email-placeholder')"
                  v-model="form.email"
                />
              </div>
              <div class="form-group mb-4">
                <div
                  class="d-flex align-items-center justify-content-between mb-3"
                >
                  <label
                    for="input-pw"
                    class="label--big"
                    v-t="'account.login.password-label'"
                    >Password</label
                  >
                  <router-link
                    :to="{ name: 'Forgot password' }"
                    class="btn btn-sm btn-empty"
                    tabindex="-1"
                  >
                    <span v-t="'account.login.forgot-password'">
                      Forgot password?
                    </span>
                  </router-link>
                </div>
                <input
                  type="password"
                  class="form-control"
                  :class="{ 'is-invalid': v$.form.password.$error }"
                  id="input-pw"
                  :placeholder="$t('account.login.password-placeholder')"
                  name="password"
                  v-model="form.password"
                />
              </div>
              <div class="form-group mb-4">
                <button class="btn btn-secondary btn-stretch btn--sign-in">
                  <span v-t="'account.login.sign-in'"> Sign in </span>
                  <i class="icon icon-default icon-keyboard_arrow_right"></i>
                  <i class="icon spinner-border d-none mr-1" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </i>
                </button>
              </div>
              <div
                class="form-group d-flex flex-row align-items-center w-100 justify-content-center text-sm"
              >
                <span class="mr-2" v-t="'account.login.no-account'">
                  No account yet?
                </span>
                <a
                  :href="$t('account.login.request-access-button-url')"
                  class="btn btn-sm btn-empty"
                  target="_blank"
                >
                  <span v-t="'account.login.request-access-button-label'">
                    Request access
                  </span>
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import useValidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      v$: useValidate(),
      form: {
        email: "",
        password: "",
        errors: []
      }
    };
  },
  validations() {
    return {
      form: {
        email: { required, email },
        password: { required }
      }
    };
  },
  created() {},
  watch: {
    $route(to, from) {
      // this.$route.params.resetpassword = false;
    }
  },
  methods: {
    ...mapActions(["signIn", "logout"]),
    userLogin(e) {
      let signBtn = document.querySelector(".btn--sign-in");
      this.addLoadingIcon(signBtn);

      this.form.errors = [];
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        this.signIn(this.form)
          .then(() => {
            this.$router.push({ name: "Account Dashboard" });
          })
          .catch(() => {
            // console.log("Login failed");
            this.form.errors.push(this.$t("account.login.sign-in-incorrect"));
            this.removeLoadingIcon(signBtn);
          });
      } else {
        this.removeLoadingIcon(signBtn);
      }
    },
    addLoadingIcon(btnName) {
      let btn = btnName;
      if (btn) {
        btn.disabled = true;
        let loadingIcon = btn.querySelector(".spinner-border");
        let defaultIcon = btn.querySelector(".icon-default");
        if (loadingIcon) {
          loadingIcon.classList.add("d-block");
          if (defaultIcon) {
            defaultIcon.classList.remove("d-block");
            defaultIcon.classList.add("d-none");
          }
        }
      }
    },
    removeLoadingIcon(btnName) {
      let btn = btnName;
      if (btn) {
        btn.disabled = false;
        let loadingIcon = btn.querySelector(".spinner-border");
        let defaultIcon = btn.querySelector(".icon-default");
        if (loadingIcon) {
          loadingIcon.classList.remove("d-block");
          loadingIcon.classList.add("d-none");
          if (defaultIcon) {
            defaultIcon.classList.add("d-block");
            defaultIcon.classList.remove("d-none");
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn"])
  }
};
</script>

<style lang="css" scoped></style>
